.MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.67) !important;
  z-index: 1049;
  backdrop-filter: blur(2px);
  margin: 0 !important;
}

@media (hover: none) {
  #root .MuiIconButton-root:hover, #root .common-button:hover,
  #root .common-button:hover .MuiIconButton-root {
    background-color: white;
    color: rgb(19 120 171 / 89%)
  }
}

#root .MuiPaper-root{
  border-radius: 8px;
}

/*
  fix iOS menu backdrop error
*/
.MuiModal-root.MuiMenu-root:has(> div[style*="opacity: 0"]):not(:has(> .MuiMenu-paper)) {
  pointer-events: none;
}